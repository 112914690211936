import state from './moduleProductCategoryState';
import mutations from './moduleProductCategoryMutations';
import actions from './moduleProductCategoryActions';
import getters from './moduleProductCategoryGetters';

export default {
  isRegistered: false,
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
