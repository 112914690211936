import { ProductCategoryMutation } from '@/store/mutationTypes';

export default {
  [ProductCategoryMutation.ADD](state, item) {
    state.productCategories.unshift(item);
  },
  [ProductCategoryMutation.SET](state, productCategories) {
    state.productCategories = productCategories;
  },
  [ProductCategoryMutation.UPDATE](state, productCategory) {
    const index = state.productCategories.findIndex(
      (el) => el.id === productCategory.id
    );
    Object.assign(state.productCategories[index], productCategory);
  },
  [ProductCategoryMutation.REMOVE](state, id) {
    const productCategories = state.productCategories.filter(
      (el) => el.id !== id
    );
    state.productCategories = productCategories;
  },
};
