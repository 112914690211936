import axios from '@/axios';
import { ProductCategoryAction } from '@/store/actionTypes';
import { ProductCategoryMutation } from '@/store/mutationTypes';

export default {
  [ProductCategoryAction.add]({ commit }, item) {
    return new Promise((resolve, reject) => {
      return axios
        .post(`/product-categories`, item)
        .then((response) => {
          commit(
            ProductCategoryMutation.ADD,
            Object.assign(item, { id: response.data.id })
          );
          resolve(response);
        })
        .catch(reject);
    });
  },
  [ProductCategoryAction.fetch]({ commit }) {
    return new Promise((resolve, reject) => {
      return axios
        .get(`/product-categories`)
        .then((response) => {
          commit(ProductCategoryMutation.SET, response.data);
          resolve(response);
        })
        .catch(reject);
    });
  },
  [ProductCategoryAction.update]({ commit }, item) {
    return new Promise((resolve, reject) => {
      return axios
        .put(`/product-categories/${item.id}`, item)
        .then((response) => {
          commit(ProductCategoryMutation.UPDATE, response.data);
          resolve(response);
        })
        .catch(reject);
    });
  },
  [ProductCategoryAction.remove]({ commit }, id) {
    return new Promise((resolve, reject) => {
      return axios
        .delete(`/product-categories/${id}`)
        .then((response) => {
          commit(ProductCategoryMutation.REMOVE, id);
          resolve(response);
        })
        .catch(reject);
    });
  },
};
